import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Layout } from '@components/Layout';
import { Home } from '@pages/Home';
import { NotFoundPage } from '@pages/NotFound';

export const Router: FC = () => {
  return (
    <Routes>
      <Route element={<Layout />} path="/">
        <Route element={<Home />} index />
        <Route element={<NotFoundPage />} path="*" />
      </Route>
    </Routes>
  );
};
