import { AlphaBadge, BetaBadge as BetaBadgeIcon } from '@deepup/icons';
import { Box, Chip, useMediaQuery, useTheme } from '@mui/material';

export const Badge = ({ isAlpha }: { isAlpha?: boolean }) => {
  const theme = useTheme();
  const isSmartphoneLayout = useMediaQuery(theme.breakpoints.only('xs'));

  if (isSmartphoneLayout) {
    return (
      <Box position="absolute" right={theme.spacing(-1)} top={theme.spacing(-1)}>
        <Chip
          color="info"
          label={isAlpha ? 'ALPHA' : 'BETA'}
          size="small"
          sx={{
            color: theme.palette.common.white,
            fontWeight: theme.typography.fontWeightBold,
          }}
          variant="filled"
        />
      </Box>
    );
  }

  return (
    <Box position="absolute" right={0} top={0}>
      {isAlpha ? <AlphaBadge fontSize={80} /> : <BetaBadgeIcon fontSize={80} />}
    </Box>
  );
};
