import { useAuth0 } from '@auth0/auth0-react';
import { Stack, Typography, styled } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 'bold',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(5),
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(2.5),
  },
}));

export const Heading = () => {
  const { user } = useAuth0();
  const { t } = useTranslation();

  return (
    <Stack gap={2} mb={{ xs: 4, sm: 6, md: 10 }}>
      <Title>{t('pages.home.greeting', { name: user?.given_name })}</Title>
      <SubTitle>
        <Trans i18nKey={'pages.home.subtitle'} />
      </SubTitle>
    </Stack>
  );
};
