import type { AppAuthorizationResponse } from '@hooks/api/useAppAuthorizationApi';
import type { AppId } from '@typings/AppInfo';

export function isAppUnauthorized(appId: AppId, appAuthorizationData?: AppAuthorizationResponse) {
  const app = appAuthorizationData?.find(({ id }) => id === appId);

  // don't disable apps when appAuthorizationData is undefined to not block the user incorrectly
  if (appAuthorizationData === undefined || app === undefined) return true;

  return !app.authorized;
}
