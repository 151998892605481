import { Grid } from '@mui/material';

import { AppTile } from '@components/AppTile';
import type { AppInfo } from '@typings/AppInfo';

interface AppGalleryProps {
  appsInfo: AppInfo[];
}

export const AppGallery: React.FC<AppGalleryProps> = ({ appsInfo }) => {
  const appTiles = appsInfo.map((appInfo) => (
    <Grid item key={appInfo.name} md={4} xs={6}>
      <AppTile appInfo={appInfo} />
    </Grid>
  ));

  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      {appTiles}
    </Grid>
  );
};
