import { useAuth0 } from '@auth0/auth0-react';
import { LoadingScreen } from '@deepup/loading-screen';
import { Alert, Container } from '@mui/material';
import type { ReactElement } from 'react';

import { useEnvironment } from '@hooks/useEnvironment';

export const Auth = ({ children }: { children: ReactElement }): ReactElement => {
  const { isAuthenticated, isLoading, error, loginWithRedirect } = useAuth0();
  const { isDev } = useEnvironment();

  if (isLoading) return <LoadingScreen />;

  if (!isAuthenticated && error) {
    return (
      <Container>
        <Alert severity="error">{error.message}</Alert>
      </Container>
    );
  }

  if (!isAuthenticated) {
    loginWithRedirect({ appState: { returnTo: window.location.href } }).catch(
      (e) => isDev && console.error(e),
    );

    return <LoadingScreen />;
  }

  return children;
};
