import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './main.css';

import './i18n.config';

import { App } from '@components/App';

const renderApp = () =>
  createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
      <App />
    </StrictMode>,
  );

const enableMocking = async () => {
  const { worker } = await import('./__mocks__/browser');

  return worker.start();
};

const useMockServiceWorker =
  import.meta.env.DEV && import.meta.env.VITE_ENABLE_MOCK_SERVICE_WORKER === 'true';

if (useMockServiceWorker) {
  // mock api must be enabled before app is rendered
  enableMocking().then(renderApp);
} else {
  // render app normally
  renderApp();
}
