import { Paper, styled } from '@mui/material';

export const Tile = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isUnauthorized',
})<{
  isUnauthorized: boolean;
}>(({ theme, isUnauthorized }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[3],
  transition: 'transform 0.2s, box-shadow 0.2s',
  minHeight: theme.spacing(42),
  opacity: isUnauthorized ? 0.38 : 1,
  cursor: isUnauthorized ? 'not-allowed' : 'pointer',

  ':hover': isUnauthorized ? null : { transform: 'scale(1.03)', boxShadow: theme.shadows[7] },
  ':active': isUnauthorized ? null : { transform: 'scale(0.97)', boxShadow: theme.shadows[2] },

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },

  [theme.breakpoints.down('md')]: {
    minHeight: 0,
  },
}));
