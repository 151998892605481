import useSWR from 'swr';

import { useJsonFetch } from '@hooks/api/useJsonFetch';
import { useEnvironment } from '@hooks/useEnvironment';
import type { AppId } from '@typings/AppInfo';

export type AppAuthorizationResponse = { id: AppId; authorized: boolean }[];

export const useAppAuthorizationApi = () => {
  const { portalApiUrl } = useEnvironment();
  const appAuthorizationEndpoint = `${portalApiUrl}app-authorization`;
  const jsonFetch = useJsonFetch<AppAuthorizationResponse>();
  const response = useSWR<AppAuthorizationResponse>(appAuthorizationEndpoint, jsonFetch, {});

  return response;
};
