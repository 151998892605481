import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

export class FetchError extends Error {
  public status?: number;

  constructor(message: string, status?: number) {
    super(message);
    this.name = 'FetchError';
    this.status = status;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }
  }
}

export const useJsonFetch = <T>(
  withBearer = true,
): ((url: string, fetchInit: RequestInit) => Promise<T>) => {
  const { getAccessTokenSilently } = useAuth0();

  const handler = useCallback(
    async (url: string, fetchInit: RequestInit) => {
      let headers = {
        ...fetchInit?.headers,
      };

      if (withBearer) {
        headers = {
          ...headers,
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        };
      }

      const response = await fetch(url, {
        ...fetchInit,
        headers,
      });

      // If the response is not ok, throw an error
      if (!response.ok) {
        const errorText = await response.text();

        throw new FetchError(errorText, response.status);
      }

      // If the response is no json, return the response as text
      if (response.headers.get('Content-Type') !== 'application/json') {
        return response.text();
      }

      // Happy path, return the data as JSON
      return response.json();
    },
    [getAccessTokenSilently, withBearer],
  );

  return handler;
};
