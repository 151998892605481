import { LockClosedFilled } from '@deepup/icons';
import { styled, Box, useMediaQuery, useTheme } from '@mui/material';

const LockIconContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    top: theme.spacing(3),
    left: theme.spacing(3),
  },
}));

export const UnauthorizedBadge = () => {
  const theme = useTheme();
  const isSmartphoneViewport = useMediaQuery(theme.breakpoints.down('sm'));

  const iconSize = isSmartphoneViewport ? theme.spacing(3) : theme.spacing(4);

  return (
    <LockIconContainer data-testid="lock-icon">
      <LockClosedFilled height={iconSize} width={iconSize} />
    </LockIconContainer>
  );
};
