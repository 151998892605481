import { NotFoundError } from '@deepup/error-states';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const NotFoundPage: FC = () => {
  const { t } = useTranslation();

  return (
    <NotFoundError caption={t('pages.notFound.caption')} details={t('pages.notFound.details')} />
  );
};
