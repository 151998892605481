import { Auth0Provider } from '@auth0/auth0-react';
import { LoadingScreen } from '@deepup/loading-screen';
import { ThemePaletteModeContext, ThemeProvider, useDeepUpTheme } from '@deepup/mui-theme-deepup';
import { CssBaseline } from '@mui/material';
import { Suspense, type FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Auth } from '@components/Auth';
import { useEnvironment } from '@hooks/useEnvironment';

import { Router } from '../../Router';

export const App: FC = () => {
  const { auth0ClientId, auth0Domain, auth0Audience } = useEnvironment();
  const { themePaletteModeContextValue, theme } = useDeepUpTheme();

  return (
    <ThemePaletteModeContext.Provider value={themePaletteModeContextValue}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Auth0Provider
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: auth0Audience,
          }}
          clientId={auth0ClientId}
          domain={auth0Domain}
        >
          <Auth>
            <BrowserRouter>
              <Suspense fallback={<LoadingScreen />}>
                <Router />
              </Suspense>
            </BrowserRouter>
          </Auth>
        </Auth0Provider>
      </ThemeProvider>
    </ThemePaletteModeContext.Provider>
  );
};
