import { Link, Box, Stack, Typography, styled, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { AppInfo } from '@typings/AppInfo';

import { Badge } from './Badge';
import { Tile } from './Tile';
import { UnauthorizedBadge } from './UnauthorizedBadge';

interface AppTileProps {
  appInfo: AppInfo;
}

const IconContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: theme.spacing(8),
  height: theme.spacing(8),

  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(2.5),
    width: theme.spacing(11),
    height: theme.spacing(11),
  },
}));

const NameContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: theme.spacing(8),
}));

const Name = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(2.5),
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  display: 'none',
  textAlign: 'center',
  marginTop: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}));

export const AppTile = ({ appInfo }: AppTileProps) => {
  const { name, Icon, description, url, isUnauthorized, isBeta, isAlpha } = appInfo;

  const { t } = useTranslation();

  const content = (
    <Tile data-testid={`tile-${name}`} isUnauthorized={isUnauthorized}>
      {isBeta && <Badge />}
      {isAlpha && <Badge isAlpha />}
      {isUnauthorized && <UnauthorizedBadge />}
      <IconContainer>
        <Icon height="100%" width="100%" />
      </IconContainer>
      <NameContainer color="text.primary">
        <Name>{name}</Name>
      </NameContainer>
      <Description color="text.secondary">{description}</Description>
    </Tile>
  );

  if (isUnauthorized) {
    return (
      <Tooltip
        arrow
        // TODO: use default props in mui-theme-deepup
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'preventOverflow',
                options: {
                  padding: 8,
                },
              },
            ],
          },
        }}
        title={
          <Typography variant="subtitle2">
            {t('pages.home.messages.appUnauthorized', { appName: name })}
          </Typography>
        }
      >
        {content}
      </Tooltip>
    );
  }

  return (
    <Link href={url} underline="none">
      {content}
    </Link>
  );
};
